/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)


require('../unify/css/unify-core.css');
require('../unify/css/unify-components.css');
require('../unify/css/unify-globals.css');

require('../css/custom.css');

// INCLUDE FIGURES
const imagesContext = require.context('../unify/figures', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

// INCLUDE IMG
const imagesContext2 = require.context('../unify/img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext2.keys().forEach(imagesContext2);
const imagesContext3 = require.context('../img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext3.keys().forEach(imagesContext3);

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
